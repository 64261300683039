import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import _ from "lodash";

import login from "./pages/Login/login";
import main from "./pages/Main/main";
import messages from "./pages/Messages/messages";
import cescos from "./pages/Cescos/cescos";
import paymentHistory from "./pages/PaymentHistory/paymentHistory";
import profile from "./pages/Profile/profile";
import vehicle from "./pages/VehicleDetails/vehicleDetails";
import vehicleDetails from "./pages/VehicleDetails/vehicleDetails";
import notFound from "./pages/NotFound/notFound";
import generalError from "./pages/GeneralError/generalError";
import certifications from "./pages/Certifications/certifications";
import payFines from "./pages/PayFines/payFines";
import maintenance from "./pages/Maintenance/maintenance";
import otherTransactions from "./pages/OtherTransactions/otherTransactions";
import courses from "./pages/Courses/courses";

import renewal from "./pages/Renewal/renewal";
import completed from "./pages/Renewal/Completed/completed";
import disclosure from "./pages/Renewal/Disclosure/disclosure";
import eligible from "./pages/Renewal/Eligible/eligible";
import notEligible from "./pages/Renewal/NotEligible/notEligible";
import questions from "./pages/Renewal/Questions/questions";
import type from "./pages/Renewal/Type/type";
import selectLocation from "./pages/Renewal/SelectLocation/selectLocation";
import missingDocs from "./pages/Renewal/MissingDocs/missingDocs";
import insurance from "./pages/Renewal/Insurance/insurance";
import voucher from "./pages/Renewal/Voucher/voucher";
import birthCertificate from "./pages/Renewal/BirthCertificate/birthCertificate";
import privateInsurance from "./pages/Renewal/PrivateInsurance/privateInsurance";
import address from "./pages/Renewal/Address/address";
import validate from "./pages/Validate/validate";
import validateDetail from "./pages/Validate/ValidateDetail/validateDetail.json";
import emailValidation from "./pages/EmailValidation/emailValidation";
import paymentEmailValidation from "./pages/PayFines/PaymentEmailValidation/paymentEmailValidation.json";

import paymentComplete from "./pages/PayFines/PaymentComplete/paymentComplete";
import paymentError from "./pages/PayFines/PaymentError/paymentError";
import loadConfig from "./pages/LoadConfig/loadConfig";
import infoModal from './components/i18n/infoModal'

import artOnlyPage from "./components/i18n/artOnlyPage";
import refreshError from "./components/i18n/freshError";
import alerts from "./components/i18n/alerts";
import fineList from "./components/i18n/fineList";
import vehicleList from "./components/i18n/vehicleList";
import contactInfo from "./components/i18n/contactInfo";
import toggle from "./components/i18n/toggle";
import wizardFooter from "./components/i18n/wizardFooter";
import layout from "./layout/i18n/layout.json";
import settings from "./pages/Settings/settings.json";
import voucherTotal from './components/widgets/VoucherTotal/voucherTotal.json'
import more from '../src/pages/More/more.json'
import vehicleTransferVoucher from './pages/VehicleTransfer/Voucher/vehicleTransferVoucher.json'
import voucherBody from "./components/widgets/VoucherBody/voucherBody.json"

import insuranceInstructions from "./pages/Insurance/Instructions/insuranceInstructions.json"
import insuranceSelection from "./pages/Insurance/Selection/insuranceSelection.json"
import insuranceTotal from "./pages/Insurance/Total/insuranceTotal.json"
import organDonor from './components/widgets/OrganDonor/organDonor.json'
import vehicleLicense from './pages/VehicleLicense/vehicleLicense.json'
import tagRenewal from './components/i18n/tagRenewal.json'

// import tagRenewalVoucher from "./pages/TagRenewal/Voucher/tagRenewalVoucher.json"

import preboarding from "./pages/Preboarding/preboarding.json"
import electronicTag from "./pages/ElectronicTag/elecronicTag.json"

import tagRenewalVoucher from "./pages/TagRenewal/Voucher/tagRenewalVoucher.json"
import support from './pages/Support/support.json'

import about from './pages/About/about.json'
import autoexpreso from './pages/Autoexpreso/autoexpreso.json'
import recalls from './pages/Recalls/recalls.json'

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        lng: _.defaultTo(localStorage.getItem("i18nextLng"), 'es-US'),
        // fallbackLng: 'en-US',
        resources: {},
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export const add = (namespace, bundle) => {
    // eslint-disable-next-line array-callback-return
    ["en", "es"].map(el => {
        i18n.addResourceBundle(el, namespace, {
            ...bundle[el]
        });
    });
};

add("login", login);
add("main", main);
add("messages", messages);
add("cescos", cescos);
add("paymentHistory", paymentHistory);
add("profile", profile);
add("vehicle", vehicle);
add("vehicleDetails", vehicleDetails);
add("notFound", notFound);
add("artOnlyPage", artOnlyPage);
add("generalError", generalError);
add("layout", layout);
add("refreshError", refreshError);
add("fineList", fineList);
add("vehicleList", vehicleList);
add("alerts", alerts);
add("certifications", certifications);
add("certifications", certifications);
add("payFines", payFines);
add("renewal", renewal);
add("contactInfo", contactInfo);
add("maintenance", maintenance);
add("otherTransactions", otherTransactions)

add("completed", completed);
add("disclosure", disclosure);
add("eligible", eligible);
add("notEligible", notEligible);
add("questions", questions);
add("type", type);
add("selectLocation", selectLocation);
add("missingDocs", missingDocs);
add("insurance", insurance);
add("voucher", voucher);
add("toggle", toggle);
add("wizardFooter", wizardFooter);
add("paymentComplete", paymentComplete);
add("paymentError", paymentError);
add("loadConfig", loadConfig);
add("infoModal", infoModal);
add("birthCertificate", birthCertificate);
add("privateInsurance", privateInsurance);
add("validate", validate);
add("validateDetail", validateDetail);
add("address", address);
add("emailValidation", emailValidation);
add("settings", settings);
add("paymentEmailValidation", paymentEmailValidation);
add("voucherTotal", voucherTotal);
add("courses", courses);
add("more", more);
add("vehicleTransferVoucher", vehicleTransferVoucher);
add("voucherBody", voucherBody);
add("insuranceTotal", insuranceTotal);
add("insuranceSelection", insuranceSelection);
add("insuranceInstructions", insuranceInstructions);
add("organDonor", organDonor);
add("vehicleLicense", vehicleLicense);
add("tagRenewalVoucher", tagRenewalVoucher);
add("tagRenewal", tagRenewal);
add("preboarding", preboarding);
add("electronicTag", electronicTag);
add("support", support);
add("about", about);
add("autoexpreso", autoexpreso);
add("recalls", recalls);

export default i18n;
