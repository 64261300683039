import React from "react";

import Text from "./Text";
import { Colors } from "../../Colors";
import { FontWeight } from "./FontSize";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types'

const ComponentTitle = (props) => {
    const { t } = useTranslation();
    const { noBorder, amount, i18nKey, sm, children, bold } = props

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: noBorder
                    ? "none"
                    : `1px solid ${Colors.borderGray}`,
                paddingBottom: "15px",
                ...props.style,
            }}
        >
            <div
                className="p-mob-only"
                role="text"
                aria-label={`${t(i18nKey)}: ${amount || ""}`}
            >
                <Text
                    className={sm ? "text" : "text-md"}
                    style={{
                        fontWeight: bold ? FontWeight.bold : FontWeight.medium,
                        letterSpacing: "0.03rem",
                        color: Colors.darkGray,
                    }}
                    i18nKey={i18nKey}
                />
            </div>
            <span aria-hidden="true">{children}</span>
        </div>
    );
};

ComponentTitle.propTypes = {
    noBorder: PropTypes.bool,
    amount: PropTypes.number,
    i18nKey: PropTypes.string,
    sm: PropTypes.bool,
    children: PropTypes.element,
    bold: PropTypes.bool
}

export default ComponentTitle;
