import React, { useState } from "react";
import ComponentTitle from "../../../components/ui/ComponentTitle";

import iconPlus from '../../../assets/images/recalls/Icon_Plus.svg'
import iconMinus from '../../../assets/images/recalls/Icon_Minus.svg'
import { Colors } from "../../../Colors";

import recallsFaqJson from '../recallsFaq.json';

const RecallsFaq = () => {

    const faqJson = recallsFaqJson;

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="mt-7">
                        <ComponentTitle i18nKey={"recalls:faq.title"} noBorder  />
                    </div>

                    <div>
                        {
                            faqJson.map((item, index) => (
                                <FaqItem key={index} question={item.question} answer={item.answer} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const FaqItem = ({ question, answer }) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div style={{ backgroundColor: isOpen && '#fff', borderTop: `1px solid ${Colors.borderGray}` }}>
            <div className="d-flex align-items-center p-3" onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
                <img src={isOpen ? iconMinus : iconPlus} alt="icon" style={{ width: 24 }} />
                <span className="text text-md text-bold ml-3">{question}</span>
            </div>
            {
                isOpen && (
                    <div className="px-3 pb-3" dangerouslySetInnerHTML={{ __html: answer }} />
                )
            }
        </div>
    )
}

export default RecallsFaq;