import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authenticate, authenticateStart } from "../../redux/login/login.combine";
import { useHistory } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-v3";

import LoginInputs from "./LoginInputs";
import SvgHeaderImg from "../../layout/SvgHeaderImg";
import Text from "../../components/ui/Text";
import { FontWeight } from "../../components/ui/FontSize";

import loginShape from "./images/Login_Shape.svg";
import moreOptionsArt from "../../assets/images/Art_MasOpciones.svg";
import marbeteDigital from "../../assets/images/Art_MarbeteDigital.svg";

import "./login.css";
import Recaptcha from "./Recaptcha";
import Button from "../../components/ui/Button";
import {Colors} from "../../Colors";
import LoginSlider from "./components/LoginSlider";

const LoginPage = (props) => {
    const [payload, handlePayload] = useState(undefined);
    const [validForLogin, handleValidForLogin] = useState(false);

    const dispatch = useDispatch();
    const recaptchaRef = useRef();

    let history = useHistory();
    const loginShapeStyle = {
        position: "absolute",
        zIndex: 0,
    };

    const loginStyle = {
        position: "relative",
    };

    useEffect(() => {
        handleLoadRecaptcha();
    }, []);

    const handleLoadRecaptcha = async () => {
        await loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    }

    useEffect(() => {
        if (validForLogin) {
            dispatch(authenticateStart());
            const { current } = recaptchaRef;
            current.execute();
        }
    }, [validForLogin]);


    const onLogin = (payload) => {
        handlePayload(payload);
        handleValidForLogin(true);

    };

    const doAuth = () => {
        if (validForLogin)
            dispatch(authenticate(payload, onSuccess));
        handleValidForLogin(false)
    }

    const onSuccess = () => {
        history.push("/landing");
    };

    let error = useSelector((state) => state?.login?.error);
    let problem = useSelector((state) => state?.login?.problem);
    let loading = useSelector((state) => state?.login?.loading);
    const { t } = useTranslation();


    return (
        <>
            <div
                id="SVGwave1BottomSMShape"
                className={`position-relative cesco-header-gradient`}
                style={{ height: "480px" }}
            >
                <div className="container pt-100 space-bottom-4 login-header">
                    <div className="row d-flex justify-content-start">
                        <div className="col-md-6 d-none d-md-block">
                            <h1
                                style={{
                                    fontWeight: FontWeight.regular,
                                    lineHeight: 1,
                                    color: "white",
                                }}
                                className="text-xxl"
                            >
                                <Text i18nKey={"login:header.titleCaption1"} />
                                <br />
                                <Text
                                    i18nKey={"login:header.titleCaption2"}
                                    style={{ color: "#ffc107" }}
                                />
                            </h1>
                            <p
                                style={{
                                    fontSize: 24,
                                    lineHeight: 1.4,
                                    color: "white",
                                    textTransform: 'inherit',
                                    fontWeight: FontWeight.regular
                                }}
                            >
                                {t("login:header.titleCaption3")}
                            </p>
                        </div>
                        <div
                            className="col-md-6 col-lg-4 col-12"
                            style={loginStyle}
                        >
                            <LoginInputs
                                onLogin={onLogin}
                                loading={loading}
                                error={error}
                                problem={problem}
                            />
                            <img
                                style={loginShapeStyle}
                                src={loginShape}
                                aria-hidden={true}
                                height={320}
                                alt="Cesco digital login"
                                width={"auto"}
                                className="d-none d-lg-block login-inputs-svg-margin"
                            />
                        </div>
                    </div>
                </div>
                <figure className="position-absolute right-0 bottom-0 left-0">
                    <SvgHeaderImg />
                </figure>
            </div>

            <div style={{borderTop: `1px solid ${Colors.borderGray}`}}>
                <div className="container" style={{paddingTop: 100, paddingBottom: 100}} >
                    <div className="row">   
                        <div className="col-12 col-lg-6 p-3"
                             style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <div>
                                <span aria-hidden={true} className="text-xxl">{t("login:marbeteDigital.title.0")}</span>
                                <span aria-hidden={true}
                                      className="text-xxl text-bold"> {t("login:marbeteDigital.title.1")}</span>
                            </div>
                            <br/>
                            <Text
                                style={{
                                    lineHeight: 1.4,
                                    color: "gray",
                                }}
                                i18nKey="login:marbeteDigital.caption"
                                className="text-md"
                            />
                            <div className="d-none d-lg-flex">
                                <button
                                    onClick={() => window.open('https://www.smarttransportation.pr.gov')}
                                    disabled={false}
                                    className="btn btn-primary btn-md mt-5"
                                >
                                    {t("login:marbeteDigital.button")}
                                </button>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 p-3">
                            <img
                                src={marbeteDigital}
                                className="img-fluid"
                                aria-hidden="true"
                                alt={t("login:marbeteDigital.alt")}
                            />

                            <div className="d-flex d-lg-none">
                                <Button
                                    onClick={() => window.open('https://www.smarttransportation.pr.gov')}
                                    disabled={false}
                                    className="btn btn-primary mt-5 btn-block"
                                >
                                    {t("login:marbeteDigital.button")}
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <div style={{border: '1px solid white', backgroundColor: 'white'}}>
                <LoginSlider/>
            </div>

            <div style={{borderTop: `1px solid ${Colors.borderGray}`}}>
                <div className="container" style={{paddingTop: 100}}>
                    <div className="row">
                        <div className="col-12 col-lg-6 p-3"
                             style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <div>
                                <span aria-hidden={true} className="text-xxl">{t("login:options.title.0")}</span>
                                <span aria-hidden={true}
                                      className="text-xxl text-bold"> {t("login:options.title.1")}</span>
                            </div>
                            {/* <span aria-hidden={true} className="text-xxl">Más Opciones</span> */}
                            <br/>
                            <Text
                                style={{
                                    lineHeight: 1.4,
                                    color: "gray",
                                }}
                                i18nKey="login:options.caption"
                                className="text-md"
                            />
                            <div className="d-none d-lg-flex">
                                <button
                                    onClick={() => history.push('/more')}
                                    disabled={false}
                                    className="btn btn-primary btn-md mt-5"
                                >
                                    {t("login:options.button")}
                                </button>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 p-3">
                            <img
                                src={moreOptionsArt}
                                className="img-fluid"
                                aria-hidden="true"
                                alt="Cesco digital más opciones"
                            />

                            <div className="d-flex d-lg-none">
                                <Button
                                    onClick={() => history.push('/more')}
                                    disabled={false}
                                    className="btn btn-primary mt-5 btn-block"
                                >
                                    {t("login:options.button")}
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <Recaptcha forwardRef={recaptchaRef} action="login" doAuth={doAuth.bind(this)} />

        </>
    );
};

export default LoginPage;
