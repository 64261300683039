import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import imageWhite from "./images/wave-1-bottom-sm-white.svg";
import imageDarkBoneWhite from "./images/wave-1-bottom-sm.svg";
import imageBoneWhite from "./images/wave-1-bottom-sm-bone.svg";
import _ from "lodash";
import SvgImage from "../components/ui/SVGImage";
import { useSelector } from 'react-redux';

const SvgHeaderImg = props => {

    const [image, setImage] = useState("imageDarkBoneWhite");
    const location = useLocation();

    const disableStatistics = useSelector(s => s?.features?.preBoarding?.disableStatistics);

    useEffect(() => {

        setImage(locationHeader(location));
    }, [location]);

    useEffect(() => {
        if (disableStatistics && location?.pathname === "/landing") {
            setImage("imageBoneWhite")
        }
    }, [disableStatistics, location])

    return (
        <>
            <SvgImage src={imageWhite} hidden={image !== "imageWhite"} />
            <SvgImage src={imageBoneWhite} hidden={image !== "imageBoneWhite"} />
            <SvgImage src={imageDarkBoneWhite} hidden={image !== "imageDarkBoneWhite"} />
        </>
    );
};

const locationHeader = (loc) => {
    let { key, pathname } = loc;

    if (_.isEmpty(pathname))
        return "imageBoneWhite";

    let img = "imageDarkBoneWhite";

    let reg = /\/validate\/.*/;
    if (reg.test(pathname)) {
        img = "imageBoneWhite"
    }

    let regE = /\/vehicle-transfer\/.*/;
    if (regE.test(pathname)) {
        img = "imageBoneWhite"
    }

    let more = /\/more\/.*/;
    if (more.test(pathname)) {
        img = "imageBoneWhite"
    }

    let tagRenewal = /\/tag-renewal\/voucher\/.*/;
    if (tagRenewal.test(pathname)) {
        img = "imageBoneWhite"
    }

    let autoexpreso = /\/autoexpreso\/.*/;
    if (autoexpreso.test(pathname)) {
        img = "imageBoneWhite"
    }

    let payAutoexpreso = /\/pay\/fines\/autoexpreso\/.*/;
    if (payAutoexpreso.test(pathname)) {
        img = "imageDarkBoneWhite"
    }

    switch (pathname) {
        case '/login':
            img = "imageBoneWhite";
            break;
        case '/validate':
            img = "imageWhite";
            break;
        case '/locations':
        case '/messages':
        case '/payment-history':
        case '/certifications':
        case '/error':
        case '/not-found':
        case '/maintenance':
        case '/settings':
        case '/renewal/not-eligible':
        case '/help':
        case '/dl':
        case '/courses':
        case '/more':
        case '/courses/dtop':
        case '/pin-support':
        case '/payment-support':
        case '/registration-support':
        case '/preboarding/instructions':
        case '/preboarding/result':
        case '/about':
        case '/recalls/unsubscribe/confirmation':
        // case '/pay/success':
            img = "imageBoneWhite";
            break
        case '/other-transactions':
            img = "imageBoneWhite"
            break
    }

    return img;
};

export default SvgHeaderImg;